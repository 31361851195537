import axios from 'axios'
import communLangage from '@/commun/commun.langage.js';
import Header from '@/components/header/header.vue'
export default {
     name: 'Projets',

     components: {
          Header
     },

     data() {
          return {
               projetEnCours: null,
               afficherDroite: null,

               projets: [],

               type: "perso"
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          },
     },

     methods: {
          recupererTexte() {
               axios.get(process.env.BASE_URL + 'assets/textes/projets/projets.json').then(result => {

                    result.data.projets.filter(x => x.type == this.type).forEach(element => {
                         var tmp = this.langage == "fr" ? element.fr : element.en;
                         tmp.code = element.code;
                         tmp.img = element.img == "" ? "point-dinterrogation.png" : element.img;
                         tmp.type = element.type;
                         this.projets.push(tmp)

                    });
               });
          },
     },

     watch: {
          langage: function () {
               this.recupererTexte();
          }
     },

     created() {
          this.type = this.$route.params.type;
          this.recupererTexte();

     }
}